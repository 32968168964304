import { ColoredTag } from "../ColoredTag"
import { useEffect, useState } from "react"

export const DailyCronCountdownTag = () => {
  const [countdown, setCountdown] = useState("")

  useEffect(() => {
    const calculateCountdown = () => {
      const now = new Date()
      const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000)
      const target = new Date(utcNow)
      target.setUTCHours(3, 0, 0, 0)

      if (utcNow.getUTCHours() >= 3) {
        target.setDate(target.getDate() + 1)
      }

      const diff = target.getTime() - utcNow.getTime()
      const hours = Math.floor(diff / (1000 * 60 * 60))
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((diff % (1000 * 60)) / 1000)

      setCountdown(`${hours}h ${minutes}m ${seconds}s`)
    }

    calculateCountdown()
    const interval = setInterval(calculateCountdown, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <ColoredTag color="green.500" fontFamily="mono">
      {countdown}
    </ColoredTag>
  )
}
