import { useQuery } from "@tanstack/react-query"
import invariant from "~/utils/invariant"
import { cacheKeys } from "../cacheKeys"
import {
  IntegrationPushStatus,
  IntegrationSyncStatus,
  SpecterProducts,
} from "@prisma/client"
import { useIntegrationApp } from "@integration-app/react"
import { z } from "zod"
import { useIntegrationService } from "./useIntegrationService"
import { integrationMetaSchema } from "../integrations"
import { ActivityLogDateRange } from "~/routes/__protected/api/integrations/$key/activity-log"
import { Enrichments as EnrichmentsType } from "~/routes/__protected/api/integrations/$key/enrichments"

export const useIntegrations = () => {
  const integrationApp = useIntegrationApp()

  return useQuery(cacheKeys.integrations, async () => {
    const req = await fetch("/api/integrations")

    invariant(req.ok, "Failed to get integrations")

    const data = await req.json()

    const integrationAppIntegrations =
      await integrationApp.integrations.findAll()

    const integrations = z
      .object({
        integrationsMeta: z.array(integrationMetaSchema),
        affinnity: z.boolean().optional(),
        hasAffinityStrategicList: z.boolean(),
        hasAffinityTalentList: z.boolean(),
        hasAffinityCompanyList: z.boolean(),
      })
      .parse(data)

    const connectedIntegrations = integrations.integrationsMeta.filter(
      (integration) =>
        integrationAppIntegrations.find(
          (appIntegration) => appIntegration.key === integration.integrationKey
        )?.connection
    )

    return {
      integrationAppIntegrations,
      integrations: {
        ...integrations,
        integrationsMeta: connectedIntegrations,
      },
    }
  })
}

export interface UserAction {
  signalId: string
  userId: string
  firstName: string
  lastName: string
  createdAt: string
}

export interface useAffinitySignalCheckResult {
  addedBy: UserAction
}

export function useAffinitySignalCheck(
  product: SpecterProducts,
  id: string,
  disabled: boolean = false
) {
  return useQuery<useAffinitySignalCheckResult>(
    cacheKeys.affinityCheckSignal(product, id),
    async () => {
      return fetch(`/api/integrations/affinity/check/${product}/${id}`).then(
        (req) => req.json()
      )
    },
    {
      refetchOnMount: false,
      enabled: !disabled,
    }
  )
}

export const useIntegrationDataSources = (
  product: SpecterProducts,
  integrationKey?: string
) => {
  const integrationService = useIntegrationService()

  return useQuery({
    queryKey: cacheKeys.integrationDataSources(product, integrationKey),
    queryFn: async () => {
      const res = await integrationService.post(`/data-sources`, {
        product,
        integrationKey,
      })

      const result = z
        .array(z.object({ value: z.string(), label: z.string() }))
        .parse(res)

      return result
    },
    enabled: !!integrationKey,
  })
}

export const useIntegrationLists = (
  integrationKey: string | undefined,
  enabled: boolean = true
) => {
  const integrationService = useIntegrationService()

  return useQuery({
    queryKey: cacheKeys.integrationLists(integrationKey),
    queryFn: async () => {
      const res = await integrationService.post(`/lists`, {
        integrationKey,
      })

      const result = z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .parse(res)

      return result
    },
    enabled,
  })
}

export const useIntegrationSyncSources = (
  integrationKey: string | undefined,
  enabled: boolean = true
) => {
  const integrationService = useIntegrationService()

  return useQuery({
    queryKey: cacheKeys.integrationSyncSources(integrationKey),
    queryFn: async () => {
      const res = await integrationService.post(`/sync-sources`, {
        integrationKey,
      })

      const result = z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .parse(res)

      return result
    },
    enabled,
  })
}

export const useIntegrationSyncs = (integrationKey?: string) => {
  return useQuery({
    refetchInterval: 5000,
    enabled: !!integrationKey,
    queryKey: cacheKeys.integrationSyncs(integrationKey),
    queryFn: async () => {
      const req = await fetch(`/api/integrations/${integrationKey}/syncs`)

      if (!req.ok) {
        throw new Error("Failed to get companies count")
      }

      const data = await req.json()

      const result = z
        .object({
          syncs: z.array(
            z.object({
              id: z.number(),
              createdAt: z.string(),
              updatedAt: z.string(),
              status: z.nativeEnum(IntegrationSyncStatus),
              error: z.string().nullable(),
            })
          ),
          totalCompanies: z.number(),
          totalUnmatched: z.number(),
          totalSyncs: z.number(),
        })
        .parse(data)

      return result
    },
  })
}

export const useIntegrationFields = (
  product: SpecterProducts,
  integrationKey: string,
  dataSource: string
) => {
  const integrationService = useIntegrationService()

  return useQuery({
    queryKey: ["fields", product, integrationKey, dataSource],
    queryFn: async () => {
      const res = await integrationService.post(`/fields`, {
        product,
        dataSource,
        integrationKey,
      })

      const result = z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .parse(res)

      return result
    },
  })
}

export const useIntegrationDataMatchingFields = (
  product: SpecterProducts,
  integrationKey: string,
  dataSource?: string
) => {
  const integrationService = useIntegrationService()

  return useQuery({
    queryKey: ["dataMatchingFields", product, integrationKey, dataSource],
    queryFn: async () => {
      const res = await integrationService.post(`/data-matching-fields`, {
        product,
        dataSource,
        integrationKey,
      })

      const result = z
        .array(
          z.object({
            value: z.string(),
            label: z.string(),
          })
        )
        .parse(res)

      return result
    },
    enabled: !!dataSource,
  })
}

const logsSchema = z.union([
  z.object({
    id: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    status: z.nativeEnum(IntegrationPushStatus),
    error: z.string().nullable(),
    type: z.literal("push"),
  }),
  z.object({
    id: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    status: z.nativeEnum(IntegrationSyncStatus),
    error: z.string().nullable(),
    type: z.literal("sync"),
  }),
])

export const useIntegrationActivityLog = (
  integrationKey?: string,
  dateRange?: ActivityLogDateRange
) => {
  return useQuery({
    queryKey: cacheKeys.integrationActivityLog(integrationKey, dateRange),
    enabled: !!integrationKey,
    refetchInterval: 3000,
    queryFn: async () => {
      const req = await fetch(
        `/api/integrations/${integrationKey}/activity-log?dateRange=${dateRange}`
      )

      if (!req.ok) {
        throw new Error("Failed to get integration activity")
      }

      const data = await req.json()

      const result = z.array(logsSchema).parse(data)

      return result
    },
  })
}

export const useIntegrationEnrichments = (integrationKey?: string) => {
  return useQuery<EnrichmentsType>({
    queryKey: cacheKeys.integrationEnrichments(integrationKey),
    queryFn: async () => {
      const req = await fetch(`/api/integrations/${integrationKey}/enrichments`)
      const data = await req.json()
      return data
    },
    enabled: !!integrationKey,
  })
}
