import {
  IntegrationEnrichmentType,
  IntegrationPushStatus,
  IntegrationSyncFrequency,
  SpecterProducts,
} from "@prisma/client"
import { z } from "zod"
import { titleCase } from "title-case"
import { getProductEntityName } from "./getProductName"
import pluralize from "pluralize"
import numeral from "numeral"
import { Enrichments } from "~/routes/__protected/api/integrations/$key/enrichments"
import { getSignalFieldTitleString } from "./signal"
import { isOneOf } from "./isOneOf"

export const integrationDataMatchingSchema = z.object({
  specterField: z.string(),
  integrationField: z.string(),
})

export type IntegrationDataMatching = z.infer<
  typeof integrationDataMatchingSchema
>

export const PRODUCTS_WITH_INTEGRATIONS = [
  SpecterProducts.company,
  SpecterProducts.people,
  SpecterProducts.talent,
  SpecterProducts.stratintel,
] as const

export type ProductWithIntegrations =
  (typeof PRODUCTS_WITH_INTEGRATIONS)[number]

export const productSupportsIntegrations = (
  product: SpecterProducts
): product is ProductWithIntegrations => {
  return isOneOf(product, PRODUCTS_WITH_INTEGRATIONS)
}

const productConfigSchema = z
  .object({
    allowCreate: z.boolean().default(false),
    dataSource: z.string().nullable().default(null),
    fieldMapping: z.record(z.string(), z.string()).nullable().default(null),
    dataMatching: integrationDataMatchingSchema.nullable().default(null),
    listId: z.string().nullable().default(null),
  })
  .default({})

export type IntegrationProductConfig = z.infer<typeof productConfigSchema>

export const integrationConfigSchema = z
  .record(z.nativeEnum(SpecterProducts), productConfigSchema)
  .nullable()
  .default({})

export type IntegrationConfig = z.infer<typeof integrationConfigSchema>

export const integrationMetaSchema = z.object({
  integrationKey: z.string(),
  isSetup: z.boolean(),
  companySyncFrequency: z.nativeEnum(IntegrationSyncFrequency).nullable(),
  companySyncSource: z.string().nullable(),
  config: integrationConfigSchema,
})

export type IntegrationMeta = z.infer<typeof integrationMetaSchema>

export const INTEGRATION_GUIDE_URL =
  "https://intercom.help/specter-labs/en/articles/10825646-integrations-guide"

const INTEGRATIONS_WITH_LISTS = ["affinity", "hubspot", "attio"]

export const integrationSupportsLists = (integrationKey: string) => {
  return INTEGRATIONS_WITH_LISTS.includes(integrationKey)
}

const INTEGRATION_NAMES: Record<string, string> = {
  affinity: "Affinity",
  airtable: "Airtable",
  pipedrive: "Pipedrive",
  attio: "Attio",
  hubspot: "Hubspot",
  salesforce: "Salesforce",
}

export const getIntegrationName = (integrationKey: string) => {
  return INTEGRATION_NAMES[integrationKey] ?? ""
}

const INTEGRATION_LOGOS: Record<string, string> = {
  affinity: "/affinity-v2.png",
  airtable: "/airtable.png",
  pipedrive: "/pipedrive.png",
  attio: "/attio.png",
  hubspot: "/hubspot.png",
  salesforce: "/salesforce.png",
}

export const getIntegrationLogo = (integrationKey: string) => {
  return INTEGRATION_LOGOS[integrationKey] ?? ""
}

const INTEGRATION_DESCRIPTIONS: Record<string, string> = {
  attio:
    "A modern CRM platform designed for fast-growing teams to manage relationships and workflows efficiently.",
  affinity:
    "A relationship intelligence CRM that leverages AI to track and manage professional connections.",
  airtable:
    "A flexible database and spreadsheet hybrid that enables teams to organize and collaborate on data.",
  hubspot:
    "A comprehensive CRM platform offering marketing, sales, and service tools to streamline customer engagement.",
  pipedrive:
    "A sales-focused CRM designed to help teams track leads, manage deals, and optimize pipelines.",
  salesforce:
    "A powerful cloud-based CRM that provides automation, analytics, and customer relationship management tools.",
}

export const getIntegrationDescription = (integrationKey: string) => {
  return INTEGRATION_DESCRIPTIONS[integrationKey] ?? ""
}

// Others are transparent bg
const INTEGRATION_COLORS: Record<string, string> = {
  airtable: "#036aef",
  hubspot: "#ff7b59",
  salesforce: "#00a1df",
}

export const getIntegrationColor = (integrationKey: string) => {
  return INTEGRATION_COLORS[integrationKey] ?? undefined
}

const SUPPORTED_FIELDS: Record<ProductWithIntegrations, string[]> = {
  [SpecterProducts.company]: [
    getSignalFieldTitleString("specter_url"),
    getSignalFieldTitleString("twitterUrl"),
    getSignalFieldTitleString("linkedinUrl"),
    getSignalFieldTitleString("instagramUrl"),
    getSignalFieldTitleString("specter_source"),
    getSignalFieldTitleString("facebookUrl"),
    getSignalFieldTitleString("specterId"),
    getSignalFieldTitleString("rank"),
    getSignalFieldTitleString("highlights"),
    getSignalFieldTitleString("companyName"),
    getSignalFieldTitleString("domain"),
    getSignalFieldTitleString("foundedYear"),
    getSignalFieldTitleString("growthStage"),
    getSignalFieldTitleString("tagline"),
    getSignalFieldTitleString("description"),
    getSignalFieldTitleString("tcp"),
    getSignalFieldTitleString("industry"),
    getSignalFieldTitleString("hqLocation"),
    getSignalFieldTitleString("totalFundingAmount"),
    getSignalFieldTitleString("lastFundingAmount"),
    getSignalFieldTitleString("lastFundingDate"),
    getSignalFieldTitleString("lastFundingType"),
    getSignalFieldTitleString("investors"),
    getSignalFieldTitleString("employeeCount"),
    getSignalFieldTitleString("webVisits"),
    getSignalFieldTitleString("webPopularityRank"),
    getSignalFieldTitleString("linkedinFollowers"),
    getSignalFieldTitleString("twitterFollowers"),
    getSignalFieldTitleString("instagramFollowers"),
  ],

  [SpecterProducts.talent]: [
    getSignalFieldTitleString("specter_url"),
    getSignalFieldTitleString("LinkedInURL"),
    getSignalFieldTitleString("TwitterURLTalent"),
    getSignalFieldTitleString("githubUrl"),
    getSignalFieldTitleString("specter_source"),
    getSignalFieldTitleString("talent_id"),
    getSignalFieldTitleString("SignalDate"),
    getSignalFieldTitleString("SignalScore"),
    getSignalFieldTitleString("SignalType"),
    getSignalFieldTitleString("SignalStatus"),
    getSignalFieldTitleString("OutOfStealthAdvantage"),
    getSignalFieldTitleString("AnnouncementDelayMonths"),
    getSignalFieldTitleString("TalentLatestSignal"),
    getSignalFieldTitleString("full_name"),
    getSignalFieldTitleString("first_name"),
    getSignalFieldTitleString("last_name"),
    getSignalFieldTitleString("Location"),
    getSignalFieldTitleString("PastPosition"),
    getSignalFieldTitleString("PastCompanyWebsite"),
    getSignalFieldTitleString("NewPosition"),
    getSignalFieldTitleString("NewCompanyWebsite"),
  ],
  [SpecterProducts.people]: [
    getSignalFieldTitleString("specter_url"),
    getSignalFieldTitleString("LinkedInURL"),
    getSignalFieldTitleString("TwitterURL"),
    getSignalFieldTitleString("githubUrl"),
    getSignalFieldTitleString("specter_source"),
    getSignalFieldTitleString("person_id"),
    getSignalFieldTitleString("talent_ids"),
    getSignalFieldTitleString("stratetic_ids"),
    getSignalFieldTitleString("first_name"),
    getSignalFieldTitleString("last_name"),
    getSignalFieldTitleString("full_name"),
    getSignalFieldTitleString("people_highlights"),
    getSignalFieldTitleString("experience"),
    getSignalFieldTitleString("current_position_title"),
    getSignalFieldTitleString("current_position_website"),
  ],
  [SpecterProducts.stratintel]: [
    getSignalFieldTitleString("specter_url"),
    getSignalFieldTitleString("Website"),
    getSignalFieldTitleString("specter_source"),
    getSignalFieldTitleString("id"),
    getSignalFieldTitleString("SignalDate"),
    getSignalFieldTitleString("SignalScore"),
    getSignalFieldTitleString("SourceType"),
    getSignalFieldTitleString("SignalType"),
    getSignalFieldTitleString("SignalSource"),
    getSignalFieldTitleString("Name"),
    getSignalFieldTitleString("HQLocation"),
    getSignalFieldTitleString("growthStage"),
  ],
}

export const getIntegrationsSupportedFields = (
  product: ProductWithIntegrations
) => {
  return SUPPORTED_FIELDS[product] ?? []
}

export const integrationConfigIsSetup = (
  config: Partial<IntegrationProductConfig>
) => {
  return z
    .object({
      dataSource: z.string(),
      fieldMapping: z.record(z.string(), z.string()),
      dataMatching: integrationDataMatchingSchema,
    })
    .safeParse(config).success
}

interface Progress {
  status: IntegrationPushStatus
  total?: number
  error?: string
}

export const getIntegrationPushStatusText = (
  progress: Progress,
  product: SpecterProducts
) => {
  const entityName = getProductEntityName(product)
  const { status, total, error } = progress

  if (status === IntegrationPushStatus.COMPLETE) {
    return "Push Complete" + (error ? ` with errors` : "")
  }

  if (status === IntegrationPushStatus.ADDING_TO_LIST && total) {
    return "Adding " + pluralize(entityName, total ?? 1, true) + " to List"
  }

  let text = [titleCase(status.replaceAll("_", " ").toLowerCase())]

  if (total) {
    text.push(numeral(total).format("0,0"))
  }

  if (
    status === IntegrationPushStatus.MATCHING ||
    status === IntegrationPushStatus.CREATING ||
    status === IntegrationPushStatus.ENRICHING
  ) {
    text.push(pluralize(entityName, total ?? 1) + "...")
  }

  return text.join(" ")
}

export const getIntegrationProductMatchingField = (
  product: SpecterProducts
) => {
  switch (product) {
    case SpecterProducts.people:
      return getSignalFieldTitleString("LinkedInURL")
    case SpecterProducts.talent:
      return getSignalFieldTitleString("LinkedInURL")
    case SpecterProducts.stratintel:
      return getSignalFieldTitleString("Website")
    default:
      return getSignalFieldTitleString("domain")
  }
}

export const getIntegrationEnrichmentTypeLabel = (
  type: IntegrationEnrichmentType,
  product: SpecterProducts
) => {
  const entityName = pluralize(getProductEntityName(product))

  switch (type) {
    case IntegrationEnrichmentType.new:
      return `New ${entityName}`
    case IntegrationEnrichmentType.new_growth_highlights:
      return `New Growth`
    case IntegrationEnrichmentType.new_funding_highlights:
      return `New Funding`
    default:
      throw new Error(`Unknown enrichment type: ${type}`)
  }
}

type GroupedEnrichment = Omit<Enrichments[number], "enrichmentType"> & {
  [IntegrationEnrichmentType.new]: boolean
  [IntegrationEnrichmentType.new_growth_highlights]: boolean
  [IntegrationEnrichmentType.new_funding_highlights]: boolean
}

export const groupEnrichments = (enrichments: Enrichments) => {
  return enrichments.reduce((acc, enrichment) => {
    const key =
      (enrichment.listId ?? enrichment.searchId) +
      "." +
      enrichment.integrationKey
    if (!key) return acc

    if (!acc[key]) {
      acc[key] = {
        ...enrichment,
        [IntegrationEnrichmentType.new]: false,
        [IntegrationEnrichmentType.new_growth_highlights]: false,
        [IntegrationEnrichmentType.new_funding_highlights]: false,
      }
    }

    acc[key][enrichment.enrichmentType] = true

    return acc
  }, {} as Record<string, GroupedEnrichment>)
}
