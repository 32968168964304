import {
  Box,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Button,
} from "@chakra-ui/react"
import { useIntercom } from "react-use-intercom"

interface Props {
  isOpen: boolean
  onClose: () => void
  product: string
}

export const TrialMessage = ({ product }: { product: string }) => {
  const intercom = useIntercom()

  return (
    <Box>
      <Box
        px={6}
        pb={2}
        pt={12}
        backgroundColor="gray.50"
        backgroundImage="url(/dot-bg.svg)"
        backgroundPosition="bottom center"
        backgroundRepeat="repeat"
        backgroundSize="18px"
        borderBottomWidth={1}
        borderColor="gray.100"
      >
        <Heading size="lg" fontWeight="extrabold">
          You're on a Trial Subscription
        </Heading>
      </Box>
      <Box p={6}>
        <Text mb={4} color="gray.500" fontSize="md">
          Ready to supercharge your experience? {product} are exclusive to
          premium users. Upgrade now to unlock full features!
        </Text>

        <Box p={0.5} borderWidth={1} rounded="lg" display="inline-block">
          <Button variant="outline" onClick={intercom.show}>
            Talk to Us
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const TrialModal = ({ isOpen, onClose, product }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent overflow="hidden" mb={12} minW={500}>
        <ModalCloseButton />
        <TrialMessage product={product} />
      </ModalContent>
    </Modal>
  )
}
