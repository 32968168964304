import { useEnv } from "~/utils/hooks/useEnv"
import invariant from "../invariant"
import { useUserPermissions } from "./useUserPermissions"

export const useIntegrationService = () => {
  const env = useEnv()
  const userPermissionsQuery = useUserPermissions()

  return {
    post: async (path: string, body: any) => {
      invariant(
        env.INTEGRATION_SERVICE_URL,
        "INTEGRATION_SERVICE_URL is not set"
      )

      const req = await fetch(`${env.INTEGRATION_SERVICE_URL}${path}`, {
        method: "POST",
        body: JSON.stringify({
          clientId: userPermissionsQuery.data?.organization?.id,
          ...body,
        }),
        headers: {
          "content-type": "application/json",
        },
      })

      if (!req.ok) {
        throw new Error(req.statusText)
      }

      const data = await req.json()

      return data
    },
  }
}
