import { Box } from "@chakra-ui/react"
import { IntegrationPushStatus } from "@prisma/client"

interface Props {
  status: IntegrationPushStatus
}

const getProgressFromStatus = (status: IntegrationPushStatus) => {
  switch (status) {
    case IntegrationPushStatus.IDLE:
      return 0
    case IntegrationPushStatus.STARTING:
      return 0.14
    case IntegrationPushStatus.MATCHING:
      return 0.28
    case IntegrationPushStatus.CREATING:
      return 0.42
    case IntegrationPushStatus.ENRICHING:
      return 0.56
    case IntegrationPushStatus.ADDING_TO_LIST:
      return 0.75
    case IntegrationPushStatus.COMPLETE:
      return 1
  }
}

export const IntegrationPushProgress = ({ status }: Props) => {
  const progress = getProgressFromStatus(status)

  if (status === IntegrationPushStatus.COMPLETE) {
    return null
  }

  return (
    <Box h={1.5} rounded="full" overflow="hidden" bg="gray.100" w="45px">
      <Box h="full" bg="brand.500" w={`${progress * 100}%`} />
    </Box>
  )
}
